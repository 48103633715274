define("ember-highcharts/utils/chart-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getSeriesChanges = getSeriesChanges;
  _exports.getSeriesMap = getSeriesMap;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  function getSeriesMap(seriesGroup) {
    var seriesMap = seriesGroup.reduce(function (seriesMap, seriesItem) {
      seriesMap[seriesItem.name] = seriesItem;
      return seriesMap;
    }, {});
    return seriesMap;
  }
  function getSeriesChanges(contentSeries, series) {
    var updatedKeys = Object.keys(contentSeries).filter(function (key) {
      var isValidKey = key !== 'data' && key.charAt(0) !== '_';
      var isValidType = ['object', 'function'].indexOf(_typeof(contentSeries[key])) === -1;
      var isTheSame = contentSeries[key] === series[key];
      return isValidKey && isValidType && !isTheSame;
    });

    // returns a list of updated keys
    return updatedKeys;
  }
});