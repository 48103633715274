define("ember-named-blocks-polyfill/helpers/-named-block-invocation", ["exports", "ember-named-blocks-polyfill"], function (_exports, _emberNamedBlocksPolyfill) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.helper(
  /**
   * @param {unknown[]} params
   * @param {object} hash
   * @returns {unknown}
   */
  function _namedBlockInvocation(params, hash) {
    (false && !(params.length === 1 && typeof params[0] === 'string') && Ember.assert('-named-block-invocation takes a single string argument', params.length === 1 && typeof params[0] === 'string'));
    (false && !(Object.keys(hash).length === 0) && Ember.assert('-named-block-invocation does not take named arguments', Object.keys(hash).length === 0));
    return (0, _emberNamedBlocksPolyfill.namedBlockInvocation)(params[0]);
  });
});