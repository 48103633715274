define("ember-i18next/index", ["exports", "ember-i18next/services/i18n", "ember-i18next/utils/macro"], function (_exports, _i18n, _macro) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "Service", {
    enumerable: true,
    get: function get() {
      return _i18n.default;
    }
  });
  Object.defineProperty(_exports, "translationMacro", {
    enumerable: true,
    get: function get() {
      return _macro.default;
    }
  });
});