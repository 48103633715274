define("ember-interactivity/utils/timeline-marking", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.INTERACTIVE_LABEL = _exports.INITIALIZING_LABEL = void 0;
  _exports.markTimeline = markTimeline;
  var INITIALIZING_LABEL = _exports.INITIALIZING_LABEL = 'Initializing';
  var INTERACTIVE_LABEL = _exports.INTERACTIVE_LABEL = 'Interactive';
  var MEASURE_LABEL = 'Latency';
  function markTimeline(type, getTimelineLabel) {
    if (performance && performance.mark) {
      // TODO: Optional heimdall integration?
      performance.mark(getTimelineLabel(type));
      if (performance.measure && type === INTERACTIVE_LABEL) {
        performance.measure(getTimelineLabel(MEASURE_LABEL), getTimelineLabel(INITIALIZING_LABEL), getTimelineLabel(INTERACTIVE_LABEL));
      }
    }
  }
});