define("ember-cli-sanitize-html/templates/components/sanitize-html", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "BetL5ymE",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,2],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[18,1,[[35,0]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[35,1]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[2,[34,0]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[34,0]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"sanitizedValue\",\"raw\",\"if\"]}",
    "moduleName": "ember-cli-sanitize-html/templates/components/sanitize-html.hbs"
  });
});