define("ember-i18next/configuration", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * ember-i18next configuration object. Used to get i18next configuration from the
   * consuming app's environment into the addon.
   *
   * @see `app/initializers/ember-i18next.js`
   */
  var _default = _exports.default = {
    i18nextOptions: null,
    load: function load(config) {
      this.i18nextOptions = config;
    }
  };
});