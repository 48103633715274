define("ember-interactivity/services/interactivity-tracking", ["exports", "ember-interactivity/utils/config"], function (_exports, _config) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend({
    trackComponent: function trackComponent() {
      if (this.isComponentInstrumentationDisabled()) {
        return;
      }
    } /* data */,
    trackRoute: function trackRoute() {
      if (this.isRouteInstrumentationDisabled()) {
        return;
      }
    } /* data */,
    trackError: function trackError() {},
    isComponentInstrumentationDisabled: function isComponentInstrumentationDisabled() {
      var options = (0, _config.default)(this);
      return options.instrumentation && options.instrumentation.disableComponents;
    },
    isRouteInstrumentationDisabled: function isRouteInstrumentationDisabled() {
      var options = (0, _config.default)(this);
      return options.instrumentation && options.instrumentation.disableRoutes;
    }
  });
});