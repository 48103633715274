define("ember-interactivity/components/interactivity-beacon", ["exports", "ember-interactivity/mixins/component-interactivity", "ember-interactivity/templates/components/interactivity-beacon"], function (_exports, _componentInteractivity, _interactivityBeacon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_componentInteractivity.default, {
    layout: _interactivityBeacon.default,
    beaconId: '',
    _latencyReportingName: Ember.computed('beaconId', function () {
      return "beacon:".concat(this.get('beaconId'));
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      Ember.run.scheduleOnce('afterRender', this, this.reportInteractive);
    }
  });
});