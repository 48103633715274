/*! jQuery Migrate v3.0.1 | (c) jQuery Foundation and other contributors | jquery.org/license */

void 0 === jQuery.migrateMute && (jQuery.migrateMute = !0), function(e) {
	"function" == typeof define && define.amd ? define([ "jquery" ], window, e) : "object" == typeof module && module.exports ? module.exports = e(require("jquery"), window) : e(jQuery, window);
}(function(e, t) {
	"use strict";
	function r(r) {
		var n = t.console;
		o[r] || (o[r] = !0, e.migrateWarnings.push(r), n && n.warn && !e.migrateMute && (n.warn("JQMIGRATE: " + r),
		e.migrateTrace && n.trace && n.trace()));
	}
	function n(e, t, n, a) {
		Object.defineProperty(e, t, {
			configurable: !0,
			enumerable: !0,
			get: function() {
				return r(a), n;
			},
			set: function(e) {
				r(a), n = e;
			}
		});
	}
	function a(e, t, n, a) {
		e[t] = function() {
			return r(a), n.apply(this, arguments);
		};
	}
	e.migrateVersion = "3.0.1", function() {
		var r = /^[12]\./;
		t.console && t.console.log && (e && !r.test(e.fn.jquery) || t.console.log("JQMIGRATE: jQuery 3.0.0+ REQUIRED"),
		e.migrateWarnings && t.console.log("JQMIGRATE: Migrate plugin loaded multiple times"),
			t.console.log("JQMIGRATE: Migrate is installed" + (e.migrateMute ? "" : " with logging active") + ", version " + e.migrateVersion));
	}();
	var o = {};
	e.migrateWarnings = [], void 0 === e.migrateTrace && (e.migrateTrace = !0), e.migrateReset = function() {
		o = {}, e.migrateWarnings.length = 0;
	}, "BackCompat" === t.document.compatMode && r("jQuery is not compatible with Quirks Mode");
	var i = e.fn.init, s = e.isNumeric, u = e.find, c = /\[(\s*[-\w]+\s*)([~|^$*]?=)\s*([-\w#]*?#[-\w#]*)\s*\]/, l = /\[(\s*[-\w]+\s*)([~|^$*]?=)\s*([-\w#]*?#[-\w#]*)\s*\]/g;
	e.fn.init = function(e) {
		var t = Array.prototype.slice.call(arguments);
		return "string" == typeof e && "#" === e && (r("jQuery( '#' ) is not a valid selector"),
			t[0] = []), i.apply(this, t);
	}, e.fn.init.prototype = e.fn, e.find = function(e) {
		var n = Array.prototype.slice.call(arguments);
		if ("string" == typeof e && c.test(e)) try {
			t.document.querySelector(e);
		} catch (a) {
			e = e.replace(l, function(e, t, r, n) {
				return "[" + t + r + '"' + n + '"]';
			});
			try {
				t.document.querySelector(e), r("Attribute selector with '#' must be quoted: " + n[0]),
					n[0] = e;
			} catch (e) {
				r("Attribute selector with '#' was not fixed: " + n[0]);
			}
		}
		return u.apply(this, n);
	};
	var d;
	for (d in u) Object.prototype.hasOwnProperty.call(u, d) && (e.find[d] = u[d]);
	e.fn.size = function() {
		return r("jQuery.fn.size() is deprecated and removed; use the .length property"),
			this.length;
	}, e.parseJSON = function() {
		return r("jQuery.parseJSON is deprecated; use JSON.parse"), JSON.parse.apply(null, arguments);
	}, e.isNumeric = function(t) {
		var n = s(t), a = function(t) {
			var r = t && t.toString();
			return !e.isArray(t) && r - parseFloat(r) + 1 >= 0;
		}(t);
		return n !== a && r("jQuery.isNumeric() should not be called on constructed objects"),
			a;
	}, a(e, "holdReady", e.holdReady, "jQuery.holdReady is deprecated"), a(e, "unique", e.uniqueSort, "jQuery.unique is deprecated; use jQuery.uniqueSort"),
		n(e.expr, "filters", e.expr.pseudos, "jQuery.expr.filters is deprecated; use jQuery.expr.pseudos"),
		n(e.expr, ":", e.expr.pseudos, "jQuery.expr[':'] is deprecated; use jQuery.expr.pseudos");
	var p = e.ajax;
	e.ajax = function() {
		var e = p.apply(this, arguments);
		return e.promise && (a(e, "success", e.done, "jQXHR.success is deprecated and removed"),
			a(e, "error", e.fail, "jQXHR.error is deprecated and removed"), a(e, "complete", e.always, "jQXHR.complete is deprecated and removed")),
			e;
	};
	var f = e.fn.removeAttr, y = e.fn.toggleClass, m = /\S+/g;
	e.fn.removeAttr = function(t) {
		var n = this;
		return e.each(t.match(m), function(t, a) {
			e.expr.match.bool.test(a) && (r("jQuery.fn.removeAttr no longer sets boolean properties: " + a),
				n.prop(a, !1));
		}), f.apply(this, arguments);
	}, e.fn.toggleClass = function(t) {
		return void 0 !== t && "boolean" != typeof t ? y.apply(this, arguments) : (r("jQuery.fn.toggleClass( boolean ) is deprecated"),
			this.each(function() {
				var r = this.getAttribute && this.getAttribute("class") || "";
				r && e.data(this, "__className__", r), this.setAttribute && this.setAttribute("class", r || !1 === t ? "" : e.data(this, "__className__") || "");
			}));
	};
	var h = !1;
	e.swap && e.each([ "height", "width", "reliableMarginRight" ], function(t, r) {
		var n = e.cssHooks[r] && e.cssHooks[r].get;
		n && (e.cssHooks[r].get = function() {
			var e;
			return h = !0, e = n.apply(this, arguments), h = !1, e;
		});
	}), e.swap = function(e, t, n, a) {
		var o, i, s = {};
		h || r("jQuery.swap() is undocumented and deprecated");
		for (i in t) s[i] = e.style[i], e.style[i] = t[i];
		o = n.apply(e, a || []);
		for (i in t) e.style[i] = s[i];
		return o;
	};
	var g = e.data;
	e.data = function(t, n, a) {
		var o;
		if (n && "object" == typeof n && 2 === arguments.length) {
			o = e.hasData(t) && g.call(this, t);
			var i = {};
			for (var s in n) s !== e.camelCase(s) ? (r("jQuery.data() always sets/gets camelCased names: " + s),
				o[s] = n[s]) : i[s] = n[s];
			return g.call(this, t, i), n;
		}
		return n && "string" == typeof n && n !== e.camelCase(n) && (o = e.hasData(t) && g.call(this, t)) && n in o ? (r("jQuery.data() always sets/gets camelCased names: " + n),
		arguments.length > 2 && (o[n] = a), o[n]) : g.apply(this, arguments);
	};
	var v = e.Tween.prototype.run, j = function(e) {
		return e;
	};
	e.Tween.prototype.run = function() {
		e.easing[this.easing].length > 1 && (r("'jQuery.easing." + this.easing.toString() + "' should use only one argument"),
			e.easing[this.easing] = j), v.apply(this, arguments);
	}, e.fx.interval = e.fx.interval || 13, t.requestAnimationFrame && n(e.fx, "interval", e.fx.interval, "jQuery.fx.interval is deprecated");
	var Q = e.fn.load, b = e.event.add, w = e.event.fix;
	e.event.props = [], e.event.fixHooks = {}, n(e.event.props, "concat", e.event.props.concat, "jQuery.event.props.concat() is deprecated and removed"),
		e.event.fix = function(t) {
			var n, a = t.type, o = this.fixHooks[a], i = e.event.props;
			if (i.length) for (r("jQuery.event.props are deprecated and removed: " + i.join()); i.length; ) e.event.addProp(i.pop());
			if (o && !o._migrated_ && (o._migrated_ = !0, r("jQuery.event.fixHooks are deprecated and removed: " + a),
				(i = o.props) && i.length)) for (;i.length; ) e.event.addProp(i.pop());
			return n = w.call(this, t), o && o.filter ? o.filter(n, t) : n;
		}, e.event.add = function(e, n) {
		return e === t && "load" === n && "complete" === t.document.readyState && r("jQuery(window).on('load'...) called after load event occurred"),
			b.apply(this, arguments);
	}, e.each([ "load", "unload", "error" ], function(t, n) {
		e.fn[n] = function() {
			var e = Array.prototype.slice.call(arguments, 0);
			return "load" === n && "string" == typeof e[0] ? Q.apply(this, e) : (r("jQuery.fn." + n + "() is deprecated"),
				e.splice(0, 0, n), arguments.length ? this.on.apply(this, e) : (this.triggerHandler.apply(this, e),
				this));
		};
	}), e.each("blur focus focusin focusout resize scroll click dblclick mousedown mouseup mousemove mouseover mouseout mouseenter mouseleave change select submit keydown keypress keyup contextmenu".split(" "), function(t, n) {
		e.fn[n] = function(e, t) {
			return r("jQuery.fn." + n + "() event shorthand is deprecated"), arguments.length > 0 ? this.on(n, null, e, t) : this.trigger(n);
		};
	}), e(function() {
		e(t.document).triggerHandler("ready");
	}), e.event.special.ready = {
		setup: function() {
			this === t.document && r("'ready' event is deprecated");
		}
	}, e.fn.extend({
		bind: function(e, t, n) {
			return r("jQuery.fn.bind() is deprecated"), this.on(e, null, t, n);
		},
		unbind: function(e, t) {
			return r("jQuery.fn.unbind() is deprecated"), this.off(e, null, t);
		},
		delegate: function(e, t, n, a) {
			return r("jQuery.fn.delegate() is deprecated"), this.on(t, e, n, a);
		},
		undelegate: function(e, t, n) {
			return r("jQuery.fn.undelegate() is deprecated"), 1 === arguments.length ? this.off(e, "**") : this.off(t, e || "**", n);
		},
		hover: function(e, t) {
			return r("jQuery.fn.hover() is deprecated"), this.on("mouseenter", e).on("mouseleave", t || e);
		}
	});
	var x = e.fn.offset;
	e.fn.offset = function() {
		var n, a = this[0], o = {
			top: 0,
			left: 0
		};
		return a && a.nodeType ? (n = (a.ownerDocument || t.document).documentElement, e.contains(n, a) ? x.apply(this, arguments) : (r("jQuery.fn.offset() requires an element connected to a document"),
			o)) : (r("jQuery.fn.offset() requires a valid DOM element"), o);
	};
	var k = e.param;
	e.param = function(t, n) {
		var a = e.ajaxSettings && e.ajaxSettings.traditional;
		return void 0 === n && a && (r("jQuery.param() no longer uses jQuery.ajaxSettings.traditional"),
			n = a), k.call(this, t, n);
	};
	var A = e.fn.andSelf || e.fn.addBack;
	e.fn.andSelf = function() {
		return r("jQuery.fn.andSelf() is deprecated and removed, use jQuery.fn.addBack()"),
			A.apply(this, arguments);
	};
	var S = e.Deferred, q = [ [ "resolve", "done", e.Callbacks("once memory"), e.Callbacks("once memory"), "resolved" ], [ "reject", "fail", e.Callbacks("once memory"), e.Callbacks("once memory"), "rejected" ], [ "notify", "progress", e.Callbacks("memory"), e.Callbacks("memory") ] ];
	return e.Deferred = function(t) {
		var n = S(), a = n.promise();
		return n.pipe = a.pipe = function() {
			var t = arguments;
			return r("deferred.pipe() is deprecated"), e.Deferred(function(r) {
				e.each(q, function(o, i) {
					var s = e.isFunction(t[o]) && t[o];
					n[i[1]](function() {
						var t = s && s.apply(this, arguments);
						t && e.isFunction(t.promise) ? t.promise().done(r.resolve).fail(r.reject).progress(r.notify) : r[i[0] + "With"](this === a ? r.promise() : this, s ? [ t ] : arguments);
					});
				}), t = null;
			}).promise();
		}, t && t.call(n, n), n;
	}, e.Deferred.exceptionHook = S.exceptionHook, e;
});