define("ember-highcharts/templates/components/high-charts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "dtnNTA7a",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"chart-container\"],[12],[13],[2,\"\\n\"],[18,1,[[35,0]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"chart\"]}",
    "moduleName": "ember-highcharts/templates/components/high-charts.hbs"
  });
});