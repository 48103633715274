define("ember-i18next/utils/macro", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = createTranslatedComputedProperty;
  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  function createTranslatedComputedProperty(key) {
    var _Ember;
    var interpolations = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var values = Object.keys(interpolations).map(function (key) {
      return interpolations[key];
    });
    var dependencies = ['i18n.locale'].concat(values);
    return (_Ember = Ember).computed.apply(_Ember, _toConsumableArray(dependencies).concat([function () {
      var i18n = Ember.get(this, 'i18n');
      (false && !(i18n) && Ember.assert("Cannot translate ".concat(key, ". ").concat(this, " does not have an i18n."), i18n));
      return i18n.t(key, mapPropertiesByHash(this, interpolations));
    }]));
  }
  function mapPropertiesByHash(object, hash) {
    var result = {};
    Object.keys(hash).forEach(function (key) {
      result[key] = Ember.get(object, hash[key]);
    });
    return result;
  }
});