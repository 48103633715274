define("ember-concurrency/-private/external/scheduler/policies/execution-states", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.makeCancelState = _exports.TYPE_STARTED = _exports.TYPE_QUEUED = _exports.TYPE_CANCELLED = _exports.STARTED = _exports.QUEUED = void 0;
  var TYPE_CANCELLED = _exports.TYPE_CANCELLED = "CANCELLED";
  var TYPE_STARTED = _exports.TYPE_STARTED = "STARTED";
  var TYPE_QUEUED = _exports.TYPE_QUEUED = "QUEUED";
  var STARTED = _exports.STARTED = {
    type: TYPE_STARTED
  };
  var QUEUED = _exports.QUEUED = {
    type: TYPE_QUEUED
  };
  var makeCancelState = _exports.makeCancelState = function makeCancelState(reason) {
    return {
      type: TYPE_CANCELLED,
      reason: reason
    };
  };
});