define("ember-interactivity/utils/date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getTimeAsFloat = getTimeAsFloat;
  /**
   * Formats the current time as a float
   *
   * @method getTimeAsFloat
   *
   * @return {number} Current time
   */
  function getTimeAsFloat() {
    return new Date().getTime() / 1000;
  }
});