define("ember-i18next/services/i18n", ["exports", "i18next", "i18next-xhr-backend", "ember-i18next/configuration"], function (_exports, _i18next, _i18nextXhrBackend, _configuration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * A service that exposes functionality from i18next.
   */
  var I18nService = Ember.Service.extend({
    i18next: null,
    isInitialized: false,
    _locale: null,
    _preInitActions: null,
    _postInitActions: null,
    init: function init() {
      this._super.apply(this, arguments);
      this.set('i18next', _i18next.default);
      this.set('_preInitActions', {});
      this.set('_postInitActions', {});
    },
    /**
     * The current locale. This is a settable computed property; when set, the locale
     * will be changed using `i18next.setLng()` and registered pre- and post-init
     * actions will run. Finally, the locale will change, triggering a refresh of
     * localized text.
     */
    locale: Ember.computed('i18next', '_locale', {
      get: function get() {
        return this.get('_locale');
      },
      set: function set(key, value) {
        var _this = this;
        var lang = value;
        if (this.get('isInitialized')) {
          this._changeLocale(lang).then(function (lang) {
            return _this.set('_locale', lang);
          });
        } else {
          this.set('_locale', lang);
        }
        return lang;
      }
    }),
    /**
     * Initializes the i18next library with configuration from the environment.
     *
     * @return {Promise} - a promise that resolves with the i18next object when
     *   i18next has finished initializing.
     */
    initLibraryAsync: function initLibraryAsync() {
      var _this2 = this;
      var i18next = this.get('i18next');
      var options = _configuration.default.i18nextOptions || {};
      return this._runPreInitActions().then(function () {
        return _this2._initLibrary();
      }).then(function () {
        return _this2._runPostInitActions();
      }).then(function () {
        _this2.set('_locale', i18next.language);
        _this2.set('isInitialized', true);
        return Ember.RSVP.resolve();
      }).catch(function (reason) {
        // eslint-disable-next-line no-console
        console.warn("A promise in the i18next init chain rejected with reason: ".concat(reason));
        if (options.rejectError) {
          return Ember.RSVP.reject(reason);
        }
      });
    },
    /**
     * Registers an action to execute before initializing i18next. Before initializing
     * the library or setting the language (which reinitializes the library), each of
     * the registered actions will be executed.
     *
     * If any pre-init actions return a promise, the service will wait until all the
     * promises have settled before initializing i18next.
     *
     * @param {String|Object} key -  the key to use to look up the action. May not be
     *   blank.
     * @param {Function} fn - a zero-argument callback function. Return a promise if
     *   the operation needs to complete before the i18next is initialized.
     */
    registerPreInitAction: function registerPreInitAction(key, fn) {
      (false && !(!Ember.isBlank(key)) && Ember.assert('Pre-init action key may not be blank', !Ember.isBlank(key)));
      (false && !(typeof fn === 'function') && Ember.assert('A pre-init action must be a function', typeof fn === 'function'));
      this.get('_preInitActions')[key] = fn;
    },
    /**
     * Removes an action from the set of actions executed before initializing i18next.
     *
     * @param {String|Object} key - the key to use to look up the action to remove.
     *   May not be blank.
     */
    unregisterPreInitAction: function unregisterPreInitAction(key) {
      (false && !(!Ember.isBlank(key)) && Ember.assert('Action key may not be blank', !Ember.isBlank(key)));
      var preInitActions = this.get('_preInitActions');
      delete preInitActions[key];
    },
    /**
     * Registers an action to execute after initializing i18next. After initializing
     * the library or setting the language (which reinitializes the library), each of
     * the registered actions will be executed.
     *
     * If any post-init actions return a promise, the service will wait until all the
     * promises have settled before triggering a UI refresh.
     *
     * @param {String|Object} key -  the key to use to look up the action. May not be
     *   blank.
     * @param {Function} fn - a zero-argument callback function. Return a promise if
     *   the operation needs to complete before the the UI is refreshed.
     */
    registerPostInitAction: function registerPostInitAction(name, fn) {
      (false && !(!Ember.isBlank(name)) && Ember.assert('Pre-init action name may not be blank', !Ember.isBlank(name)));
      (false && !(typeof fn === 'function') && Ember.assert('A post-init action must be a function', typeof fn === 'function'));
      this.get('_postInitActions')[name] = fn;
    },
    /**
     * Removes an action from the set of actions executed after initializing i18next.
     *
     * @param {String|Object} key - the key to use to look up the action to remove.
     *   May not be blank.
     */
    unregisterPostInitAction: function unregisterPostInitAction(key) {
      (false && !(!Ember.isBlank(key)) && Ember.assert('Action key may not be blank', !Ember.isBlank(key)));
      var postInitActions = this.get('_postInitActions');
      delete postInitActions[key];
    },
    /**
     * Changes the locale, ensuring that pre- and post-init actions run.
     *
     * @param {String} lang - locale code to set.
     *
     * @return {Promise} a promise that resolves with the language code when complete.
     */
    _changeLocale: function _changeLocale(lang) {
      var _this3 = this;
      var i18next = this.get('i18next');
      if (i18next && lang && i18next.language === lang) {
        return Ember.RSVP.resolve(lang);
      }
      var oldLang = this._locale;
      return this._runPreInitActions(lang).then(function () {
        return _this3._setLng(lang);
      }).then(function () {
        return _this3._runPostInitActions(oldLang);
      }).then(function () {
        return Ember.RSVP.resolve(lang);
      }).catch(function (reason) {
        // eslint-disable-next-line no-console
        console.warn("A promise in the locale change path rejected with reason: ".concat(reason));
      });
    },
    /**
     * Forwarded to `i18next.t()`.
     *
     * @param {String} path - an string that specifies the translation lookup key.
     *   Required.
     * @param {Object} values - an object that specifies values to substitute into
     *   the translation for the specified path. Optional.
     *
     * @return Localized text.
     */
    t: function t(path, values) {
      var i18next = this.get('i18next');
      return i18next.t(path, values);
    },
    /**
     * Forwarded to `i18next.addResourceBundle()`.
     */
    addResourceBundle: function addResourceBundle(lang, ns, resources, deep, overwrite) {
      return this.get('i18next').addResourceBundle(lang, ns, resources, deep, overwrite);
    },
    /**
     * Forwarded to `i18next.hasResourceBundle()`.
     */
    hasResourceBundle: function hasResourceBundle(lang, ns) {
      return this.get('i18next').hasResourceBundle(lang, ns);
    },
    /**
     * Forwarded to `i18next.getResourceBundle()`.
     */
    getResourceBundle: function getResourceBundle(lang, ns) {
      return this.get('i18next').getResourceBundle(lang, ns);
    },
    /**
     * Forwarded to `i18next.removeResourceBundle()`.
     */
    removeResourceBundle: function removeResourceBundle(lang, ns) {
      return this.get('i18next').removeResourceBundle(lang, ns);
    },
    /**
     * Forwarded to `i18next.addResource()`.
     */
    addResource: function addResource(lang, ns, key, value, options) {
      return this.get('i18next').addResource(lang, ns, key, value, options);
    },
    /**
     * Forwarded to `i18next.addResources()`.
     */
    addResources: function addResources(lang, ns, resources) {
      return this.get('i18next').addResources(lang, ns, resources);
    },
    /**
     * Forwarded to `i18next.loadNamespaces()`.
     */
    loadNamespaces: function loadNamespaces(namespaces, callback) {
      return this.get('i18next').loadNamespaces(namespaces, callback);
    },
    /**
     * Forwarded to `i18next.setDefaultNamespace()`.
     */
    setDefaultNamespace: function setDefaultNamespace(ns) {
      return this.get('i18next').setDefaultNamespace(ns);
    },
    /**
     * Alias for `t()`.
     */
    translate: function translate(key, values) {
      return this.t(key, values);
    },
    /**
     * Forwarded to `i18next.exists()`.
     */
    exists: function exists(key, options) {
      return this.get('i18next').exists(key, options);
    },
    /**
     * Gets i18next's `pluralExtensions` property.
     */
    pluralExtensions: Ember.computed('i18next', function () {
      return this.get('i18next').pluralExtensions;
    }),
    /**
     * Forwarded to `i18next.addPostProcessor()`.
     */
    addPostProcessor: function addPostProcessor(name, fn) {
      return this.get('i18next').addPostProcessor(name, fn);
    },
    /**
     * Forwarded to `i18next.applyReplacement()`.
     */
    applyReplacement: function applyReplacement(str, replacementHash, nestedKey, options) {
      return this.get('i18next').applyReplacement(str, replacementHash, nestedKey, options);
    },
    _initLibrary: function _initLibrary() {
      var i18next = this.get('i18next');
      var options = _configuration.default.i18nextOptions || {};
      var errors;

      //
      //  TODO:  Adding i18nextXHRBackend by default so that translation
      //         files can be loaded.
      //         How do we extend this so that other plugins can be added
      //         dynamically?
      //
      return i18next.use(_i18nextXhrBackend.default).init(options, function (err) {
        if (err) errors = err;
      }).then(function () {
        if (errors) {
          return Ember.RSVP.reject(errors);
        } else {
          return Ember.RSVP.resolve(i18next);
        }
      });
    },
    _setLng: function _setLng(locale) {
      var i18next = this.get('i18next');
      return i18next.changeLanguage(locale).then(function () {
        return Ember.RSVP.resolve(locale);
      });
    },
    _getActionCallHash: function _getActionCallHash(actions, lang) {
      var actionsCallHash = {};
      Object.keys(actions).forEach(function (key) {
        actionsCallHash[key] = actions[key].call(undefined, lang);
      });
      return actionsCallHash;
    },
    _runPreInitActions: function _runPreInitActions(newLang) {
      var _preInitActions = this.get('_preInitActions');
      var actionCalls = this._getActionCallHash(_preInitActions, newLang);
      return Ember.RSVP.hash(actionCalls, 'ember-i18next: pre init actions');
    },
    _runPostInitActions: function _runPostInitActions(oldLang) {
      var _postInitActions = this.get('_postInitActions');
      var actionCalls = this._getActionCallHash(_postInitActions, oldLang);
      return Ember.RSVP.hash(actionCalls, 'ember-i18next: post init actions');
    }
  });
  var _default = _exports.default = I18nService;
});