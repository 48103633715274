define("ember-pdf-js/components/pdf-js-toolbar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div>
    <button type="button" {{on "click" (fn @changePage 'next')}} disabled={{gte @page @pageTotal}}>
      Next page !
    </button>
    <button type="button" {{on "click" (fn @changePage 'prev')}} disabled={{lte @page 1}}>
      Prev page !
    </button>
    <br>
    <label>page {{@page}} of {{@pageTotal}}</label>
  </div>
  */
  {
    "id": "xQp+kdGz",
    "block": "{\"symbols\":[\"@pageTotal\",\"@page\",\"@changePage\"],\"statements\":[[10,\"div\"],[12],[2,\"\\n  \"],[11,\"button\"],[16,\"disabled\",[30,[36,0],[[32,2],[32,1]],null]],[24,4,\"button\"],[4,[38,2],[\"click\",[30,[36,1],[[32,3],\"next\"],null]],null],[12],[2,\"\\n    Next page !\\n  \"],[13],[2,\"\\n  \"],[11,\"button\"],[16,\"disabled\",[30,[36,3],[[32,2],1],null]],[24,4,\"button\"],[4,[38,2],[\"click\",[30,[36,1],[[32,3],\"prev\"],null]],null],[12],[2,\"\\n    Prev page !\\n  \"],[13],[2,\"\\n  \"],[10,\"br\"],[12],[13],[2,\"\\n  \"],[10,\"label\"],[12],[2,\"page \"],[1,[32,2]],[2,\" of \"],[1,[32,1]],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"gte\",\"fn\",\"on\",\"lte\"]}",
    "moduleName": "ember-pdf-js/components/pdf-js-toolbar.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});