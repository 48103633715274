define("ember-concurrency/-private/taskable-mixin", ["exports", "ember-concurrency/-private/utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.TASKABLE_MIXIN = void 0;
  var TASKABLE_MIXIN = _exports.TASKABLE_MIXIN = {
    _performCount: 0,
    setState: function setState(state) {
      this._performCount = this._performCount + (state.numPerformedInc || 0);
      var isRunning = state.numRunning > 0;
      var isQueued = state.numQueued > 0;
      var derivedState = Object.assign({}, state, {
        performCount: this._performCount,
        isRunning: isRunning,
        isQueued: isQueued,
        isIdle: !isRunning && !isQueued,
        state: isRunning ? "running" : "idle"
      });
      (0, _utils.assignProperties)(this, derivedState);
    },
    onState: function onState(state, task) {
      if (task.onStateCallback) {
        task.onStateCallback(state, task);
      }
    }
  };
});