define("moment/index", ["exports", "moment/lib", "ember-copy"], function (_exports, _lib, _emberCopy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function hasOwnProp(a, b) {
    return Object.prototype.hasOwnProperty.call(a, b);
  }
  function extend(a, b) {
    var _loop = function _loop(propName) {
      if (hasOwnProp(b, propName)) {
        Object.defineProperty(a, propName, {
          enumerable: true,
          configurable: true,
          get: function get() {
            return b[propName];
          },
          set: function set(newValue) {
            b[propName] = newValue;
          }
        });
      }
    };
    for (var propName in b) {
      _loop(propName);
    }

    // non-enumerable property
    ['toString', 'valueOf'].forEach(function (propName) {
      if (hasOwnProp(b, propName)) {
        Object.defineProperty(a, propName, {
          enumerable: false,
          configurable: true,
          get: function get() {
            return b[propName];
          },
          set: function set(newValue) {
            b[propName] = newValue;
          }
        });
      }
    });
    return a;
  }
  function createAugmentedMomentFrom(fn) {
    var augmentedMoment = function augmentedMoment() {
      var m = fn.apply(void 0, arguments);
      var meta = Ember.meta(m);
      meta.addMixin(_emberCopy.Copyable);
      return m;
    };
    augmentedMoment.prototype = _lib.default.prototype;
    extend(augmentedMoment, _lib.default);
    return augmentedMoment;
  }
  function compare(a, b) {
    if (_lib.default.isMoment(a) && _lib.default.isMoment(b)) {
      if (a.isBefore(b)) {
        return -1;
      } else if (a.isSame(b)) {
        return 0;
      } else {
        return 1;
      }
    }
    throw new Error('Arguments provided to `compare` are not moment objects');
  }
  _lib.default.prototype.compare = compare;
  _lib.default.compare = compare;
  _lib.default.prototype.clone = function clone() {
    return augmentedMoment(this);
  };
  _lib.default.prototype.copy = function copy(deep) {
    return this.clone();
  };
  var augmentedMoment = createAugmentedMomentFrom(_lib.default);
  ['utc', 'unix'].forEach(function (methodName) {
    augmentedMoment[methodName] = createAugmentedMomentFrom(_lib.default[methodName]);
  });
  var _default = _exports.default = augmentedMoment;
});