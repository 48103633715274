define("ember-did-resize-modifier/services/did-resize-detector", ["exports", "element-resize-detector"], function (_exports, _elementResizeDetector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.detector = (0, _elementResizeDetector.default)({
        strategy: "scroll"
      });
    },
    setup: function setup(element, callback, options) {
      this.detector.listenTo(options, element, callback);
    },
    teardown: function teardown(element, callback) {
      this.detector.removeListener(element, callback);
    }
  });
});