define("@ember/test-waiters/wait-for", ["exports", "@ember/test-waiters/wait-for-promise", "@ember/test-waiters/build-waiter"], function (_exports, _waitForPromise, _buildWaiter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = waitFor;
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
  /**
   * A convenient utility function to simplify waiting for async. Can be used
   * in both decorator and function form. When applied to an async function, it
   * will cause tests to wait until the returned promise has resolves. When
   * applied to a generator function, it will cause tests to wait until the
   * returned iterator has run to completion, which is useful for wrapping
   * ember-concurrency task functions.
   *
   *
   * @public
   * @param promise {Function} An async function or a generator function
   * @param label {string} An optional string to identify the promise
   *
   * @example
   *
   * import Component from '@ember/component';
   * import { waitFor } from 'ember-test-waiters';
   *
   * export default Component.extend({
   *   doAsyncStuff: waitFor(async function doAsyncStuff() {
   *     await somethingAsync();
   *   }
   * });
   *
   * @example
   *
   * import Component from '@ember/component';
   * import { waitFor } from 'ember-test-waiters';
   *
   * export default class Friendz extends Component {
   *   @waitFor
   *   async doAsyncStuff() {
   *     await somethingAsync();
   *   }
   * }
   *
   * @example
   *
   * import Component from '@ember/component';
   * import { task } from 'ember-concurrency';
   * import { waitFor } from 'ember-test-waiters';
   *
   * export default Component.extend({
   *   doTaskStuff: task(waitFor(function* doTaskStuff() {
   *     yield somethingAsync();
   *   }
   * });
   *
   * @example
   *
   * import Component from '@ember/component';
   * import { task } from 'ember-concurrency-decorators';
   * import { waitFor } from 'ember-test-waiters';
   *
   * export default class Friendz extends Component {
   *   @task
   *   @waitFor
   *   *doTaskStuff() {
   *     yield somethingAsync();
   *   }
   * }
   *
   */

  function waitFor() {
    for (var _len = arguments.length, args = new Array(_len), _key2 = 0; _key2 < _len; _key2++) {
      args[_key2] = arguments[_key2];
    }
    var isFunction = args.length < 3;
    if (isFunction) {
      var _ref = args,
        _ref2 = _slicedToArray(_ref, 2),
        _fn = _ref2[0],
        _label = _ref2[1];
      return wrapFunction(_fn, _label);
    } else {
      var _ref3 = args,
        _ref4 = _slicedToArray(_ref3, 4),
        _descriptor = _ref4[2],
        _label2 = _ref4[3];
      if (!false /* DEBUG */) {
        return _descriptor;
      }
      var _fn2 = _descriptor.value;
      _descriptor.value = wrapFunction(_fn2, _label2);
      return _descriptor;
    }
  }
  function wrapFunction(fn, label) {
    if (!false /* DEBUG */) {
      return fn;
    }
    return function () {
      for (var _len2 = arguments.length, args = new Array(_len2), _key3 = 0; _key3 < _len2; _key3++) {
        args[_key3] = arguments[_key3];
      }
      var result = fn.call.apply(fn, [this].concat(args));
      if (isThenable(result)) {
        return (0, _waitForPromise.default)(result, label);
      } else if (isGenerator(result)) {
        return waitForGenerator(result, label);
      } else {
        return result;
      }
    };
  }
  function isThenable(maybePromise) {
    var type = _typeof(maybePromise);
    return (maybePromise !== null && type === 'object' || type === 'function') && typeof maybePromise.then === 'function';
  }
  function isGenerator(maybeGenerator) {
    // Because we don't have Symbol.iterator in IE11
    return typeof maybeGenerator.next === 'function' && typeof maybeGenerator.return === 'function' && typeof maybeGenerator.throw === 'function';
  }
  var GENERATOR_WAITER = (0, _buildWaiter.default)('@ember/test-waiters:generator-waiter');
  function waitForGenerator(generator, label) {
    GENERATOR_WAITER.beginAsync(generator, label);
    var isWaiting = true;
    function stopWaiting() {
      if (isWaiting) {
        GENERATOR_WAITER.endAsync(generator);
        isWaiting = false;
      }
    }
    return {
      next: function next() {
        var hasErrored = true;
        try {
          var val = generator.next.apply(generator, arguments);
          hasErrored = false;
          if (val.done) {
            stopWaiting();
          }
          return val;
        } finally {
          // If generator.next() throws, we need to stop waiting. But if we catch
          // and re-throw exceptions, it could move the location from which the
          // uncaught exception is thrown, interfering with the developer
          // debugging experience if they have break-on-exceptions enabled. So we
          // use a boolean flag and a finally block to emulate a catch block.
          if (hasErrored) {
            stopWaiting();
          }
        }
      },
      return: function _return() {
        stopWaiting();
        return generator.return.apply(generator, arguments);
      },
      throw: function _throw() {
        stopWaiting();
        return generator.throw.apply(generator, arguments);
      }
    };
  }
});